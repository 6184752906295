import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Search } from '@mui/icons-material';

const fontSize = "30px"

export const vendorInfoDashboardHeaders = [
  {
    label: "Clients",
    key: "clients",
    icon: <BusinessIcon sx={{color: "skyblue", fontSize}} />,
    color: "#bae6fd"
  },
  {
    label: "Partners",
    key: "partners",
    icon: <BusinessIcon sx={{color: "skyblue", fontSize}} />,
    color: "#bae6fd"
  },
  {
    label: "Resources",
    key: "consultant",
    icon: <PeopleIcon sx={{color: "#3b82f6", fontSize}}/>,
  },
  {
    label: "Workroom", 
    key: "workRoom",
    icon: <HomeRepairServiceIcon sx={{color: "#ec4899", fontSize}}/>,
  },
  {
    label: "Timesheets",
    key: "timesheet",
    icon: <AccessTimeIcon sx={{color: "#3b82f6", fontSize}}/>
  },
  {
    label: "Invoices",
    key: "invoice",
    icon: <PaymentsIcon sx={{color: "#f59e0b", fontSize}} />
  },
  {
    label: "Marketplace Jobs", 
    key: "marketplaceJobs",
    icon: <Search sx={{color: "#ec4899", fontSize}}/>, 
  },
]

export const clientInfoDashboardHeaders = [
  {
    label: "Vendors",
    key: "vendors",
    icon: <BusinessIcon sx={{color: "skyblue", fontSize}} />,
    color: "#bae6fd"
  },
  {
    label: "Resources",
    key: "consultants",
    icon: <PeopleIcon sx={{color: "#3b82f6", fontSize}}/>,
  },
  {
    label: "Workroom",
    key: "workRoom",
    icon: <HomeRepairServiceIcon sx={{color: "#ec4899", fontSize}}/>,
  },
  {
    label: "Timesheets",
    key: "timesheet",
    icon: <AccessTimeIcon sx={{color: "#3b82f6", fontSize}}/>
  },
  {
    label: "Invoices",
    key: "invoice",
    icon: <PaymentsIcon sx={{color: "#f59e0b", fontSize}} />
  },
  {
    label: "Marketplace Jobs",
    key: "marketplaceJobs",
    icon: <Search sx={{color: "#ec4899", fontSize}}/>,
  },
]

export const revenueHeaders = {
  label: "Revenue",
  key: "revenue",
  icon: <AccountBalanceIcon sx={{color:'#16a34a'}} />,
  color: "#16a34a"
}