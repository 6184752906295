import React, { useEffect } from 'react'
import { Outlet, useOutletContext, useLocation  } from 'react-router-dom'

const UserManager = () => {
  const location = useLocation();
  const context = useOutletContext();

  useEffect(() => {
    if (location.state?.fromDashboard) {
      if (location.pathname.includes('/UserManager/Client')) {
        context(["Dashboard", "Client"]);
      } else if (location.pathname.includes('/UserManager/Vendor')) {
        context(["Dashboard", "Vendor"]);
      }
    } else {
      context(["User Manager", location.pathname.split('/').pop()]);
    }
  }, [location.pathname]);

  return (
    <Outlet context={context}/>
  )
}

export default UserManager