import api from "../../../utils/api"

export const getAllDashboardCount = async() => {
  try {
    const res = await api.get('user/getUserCountForadmin');
    return res;
  } catch (err) {
    return err;
  }
}


export const getMarketplaceJobsData = async(page, limit, search, userid) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_BASE_MARKETPLACE_URL_NEW}api/market-place-jobs/get/dashboardDetails?page=${page}&limit=${limit}&search=${search || ""}&user_id=${userid}`,
    );
    return res;
  } catch (err) {
    return err;
  }
};
