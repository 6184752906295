import React from "react";

function HomeDashboardCards({label = '', count = 0, icon = <></>, iconColor = '', index = 0 , onClick}) {

  const countinObject = typeof count === 'object';

  return (
    <div
      key={index}
      className={`flex flex-col p-4 m-1 bg-[rgb(255,255,255)] shadow-md rounded-lg w-[32%] ${label !== "AI Mock Interviews" && onClick ? 'cursor-pointer' : ''}`}
      onClick={label !== "AI Mock Interviews" && onClick ? onClick : undefined}
    >
      <div className="flex justify-between">
        <span className="text-xl font-bold">{label}</span>
        <div className={`bg-[${iconColor}]`}>{icon}</div>
      </div>
      <div className={`flex ${countinObject ? "justify-between" : "justify-end h-full"} pt-4`}>
      {countinObject && label === "AI Mock Interviews" && (
          <div className="flex flex-col">
            <span>Paid Mock Interviews: {count.mockInterview}</span>
            <span>Free Mock Interviews: {count.freeTrial}</span>
          </div>
        )}
        {countinObject && label !== "AI Mock Interviews" && 
        <div className="flex flex-col">
          {count?.mock_interview_credits != null && <span>Credit Purchased: {count?.mock_interview_credits}</span>}
          {count?.totalInterviewCount != null && <span>Used Credits: {count?.totalInterviewCount}</span>}
          {count?.Working_With_Vendors && <span>Working With Vendor: {count?.Working_With_Vendors}</span>}
          {count?.OPEN && <span>Open: {count?.OPEN}</span>}
          {count?.OPEN ? <div className="flex">
            {count?.CLOSED ? <span>Closed: {count?.CLOSED}</span> : <span>Closed: 0</span>}
            {/* {count?.HIRED ? <span className="pl-2">Hired: {count?.HIRED}</span> : <span className="pl-2">Hired: 0</span>} */}
          </div> : <></>}
          {count?.Open_Resources || count?.Published_Resources ? <span className="font-bold pt-1">Marketplace</span> : <></>}
          {count?.Open_Resources && <span className="pl-2"> - Open Resource: {count?.Open_Resources}</span>}
          {count?.Published_Resources && <span className="pl-2"> - Published Resource: {count?.Published_Resources}</span>}
        </div>}
        <div className={`flex flex-col justify-end items-end h-full ${countinObject ? "" : "pr-1 pt-2" }`}>
          <span className="text-4xl font-bold">{countinObject ? count?.Working_With_Vendors || count?.Open_Resources || count?.Published_Resources ? (count?.Working_With_Vendors + count?.Open_Resources + count?.Published_Resources) : count?.OPEN || count?.CLOSED || count?.HIRED && <span className="text-4xl font-bold">{count?.OPEN + count?.CLOSED + count?.HIRED}</span> : count}</span>
        </div>
      </div>
    </div>
  );
}

export default HomeDashboardCards;
