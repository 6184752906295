import React, { useEffect, useState } from 'react'
import { DashboardCards, HomeDashboardCards } from '../../components';
import { adminDashboardHeaders, clientInfoDashboardHeaders, vendorInfoDashboardHeaders } from './utils';
import { useLocation, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { getAllDashboardCount } from './helper';
import {ArrowBack} from "@mui/icons-material";


const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const context = useOutletContext();
  const [adminDashboardCount, setAdminDashboardCount] = useState({});

  useEffect(() => {
    context(["Dashboard"]);
  },[location.pathname])

  useEffect(() => {
    getAllDashboardCount().then((res) => {
      setAdminDashboardCount(res?.data);
    }).catch((err) =>  {
      console.log(err);
    }).finally();
  }, [])

  const handleClick = (path) => {
  if (path) {
    navigate(path, { 
      state: { 
        fromDashboard: true 
      }
    }); 
  }
};

  const renderUserDashboardCards = (item, index) => {
    const {label, icon, color, key , path} = item;   
    return(
      <HomeDashboardCards label={label} icon={icon} iconColor={color} count={adminDashboardCount[key]} index={index}  onClick={path ? () => handleClick(path) : undefined}/>
    )
  }

  return (
    <div className='flex flex-col'>
      <div className="flex flex-col pl-6 pb-4">
        <h1 className='text-3xl'>Overview</h1>
      </div>
      <div className="flex flex-wrap justify-start pl-6">
        {adminDashboardHeaders.map(renderUserDashboardCards)}
      </div>
      {/* <div className="flex pl-6 pt-4">
        <RevenueCards {...userDashboardData[revenueHeaders.key]} iconData={{icon: revenueHeaders.icon, color: revenueHeaders.color}} label={revenueHeaders.label} />
      </div> */}
    </div>
  )
}

export default Dashboard;