import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryParams } from '../hooks';

function DashboardCards(props) {
  const { label, iconData, current, total, Open, hired, Ongoing, closed, rejected, paid, unpaid, draft, requested = 0, approved, pending, vendorRoute, index } = props;

  const isRedirect = ["Clients", "Partners", "Vendors", "Resources", "Marketplace Jobs"].includes(label)

  const navigate = useNavigate();
  const param = useParams();
  const { getParam } = useQueryParams();
  const { pathname } = useLocation();

  const isVendor = pathname.includes("Vendor");

  // const handleNavigateUser = () => {
  //   if(isRedirect) {
  //     if(isVendor) {
  //       console.log("vendor route");
  //       label === "Clients" && navigate(`/UserManager/Vendor/cvr-list/${param.userid}?connectiontitle=CLIENT&page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
  //       label === "Partners" && navigate(`/UserManager/Vendor/cvr-list/${param.userid}?connectiontitle=VENDOR&page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
  //       label === "Resources" && navigate(`/UserManager/Vendor/cvr-list/${param.userid}?connectiontitle=RESOURCE&page=${getParam("page")}&pageSize=${getParam("pageSize")}`);  
  //     } else {
  //       label === "Clients" && navigate(`/UserManager/Client/vendor-list/${param.userid}?connectiontitle=CLIENT&page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
  //       label === "Vendors" && navigate(`/UserManager/Client/vendor-list/${param.userid}?connectiontitle=VENDOR&page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
  //       label === "Resources" && navigate(`/UserManager/Client/vendor-list/${param.userid}?connectiontitle=RESOURCE&page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
  //     }
  //   }
  // }

  const handleNavigateUser = () => {
    if (!isRedirect) return;
    if (label === "Marketplace Jobs") {
      const baseRoute = isVendor
        ? `/UserManager/Vendor/vendor-info/${param.userid}/marketplaceJobs`
        : `/UserManager/Client/client-info/${param.userid}/marketplaceJobs`;
      navigate(`${baseRoute}?page=${getParam("page")}&pageSize=${getParam("pageSize")}&user-name=${getParam("user-name")}`);
      return;
    }
    const connectionTitle = isVendor ? "VENDOR" : "CLIENT";
    let baseUrl;
    if (isVendor) {
      baseUrl = `/UserManager/Vendor/cvr-list/${param.userid}`;
    } else {
      baseUrl = `/UserManager/Client/vendor-list/${param.userid}`;
    }
    if (["Clients", "Partners", "Resources", "Vendors"].includes(label)) {
      navigate(`${baseUrl}?connectiontitle=${connectionTitle}&page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
    } else {
      console.error("Invalid label provided");
    }
  };


  return (
    <div key={index} className={`flex flex-col p-4 m-1 bg-[rgb(255,255,255)] shadow-md rounded-lg w-[32%] ${isRedirect && "hover:bg-slate-50 cursor-pointer"}`} onClick={handleNavigateUser}>
      <div className="flex justify-between pb-4">
        <span className='text-xl font-bold'>{label}</span>
        <div className={`bg-[${iconData?.color}]`}>{iconData.icon}</div>
      </div>
      {current && <div className="flex justify-between">
        <div className="flex flex-col">
          <span>Current: {current}</span>
          <span>Requested: {requested}</span>
        </div>
        <div className="flex flex-col justify-end">
          <span className='text-4xl font-bold'>{current + requested}</span>
        </div>
      </div>}
      {approved && <div className="flex justify-between">
        <div className="flex flex-col">
          <span>Approved: {approved}</span>
          <div className="flex">
            <span>Pending: {pending}</span>
            <span className='pl-4'>Rejected: {rejected}</span>
          </div>
        </div>
        <div className="flex flex-col justify-end">
          <span className='text-4xl font-bold'>{approved + pending + rejected}</span>
        </div>
      </div>}
      {total && <div className="flex justify-between">
        <div className="flex flex-col">
          <span>Total: {total}</span>
        </div>
        <div className="flex flex-col justify-end">
          <span className='text-4xl font-bold'>{total}</span>
        </div>
      </div>}
      {(Ongoing || hired  || closed  || Open ) && <div className="flex justify-between">
        <div className="flex flex-col">
          {Open> 0 &&<span>Open: {Open}</span>}
          <div className="flex">
            {Ongoing > 0 && <span className='pr-4'>Ongoing: {Ongoing}</span>}
            {closed > 0 && <span>Closed: {closed}</span>}
            {hired > 0 && <span className='pl-4'>Hired: {hired}</span>}
          </div>
        </div>
        <div className="flex flex-col justify-end">
          {
            Ongoing || hired || closed || Open ? (
              <span className="text-4xl font-bold">
                {(Open || 0) + (Ongoing || 0) + (hired || 0) + (closed || 0)}
              </span>
            ) : (
              <span className="text-4xl font-bold">0</span>
            )
          }
        </div>
      </div>}

      {(paid || unpaid) && <div className="flex justify-between">
    <div className="flex flex-col">
      {paid !== undefined && paid !== null && <span>Paid: {paid}</span>}
      <div className="flex">
        {unpaid !== undefined && unpaid !== null && <span className='pr-4'>Ongoing: {unpaid}</span>}
        {paid !== undefined && paid !== null && vendorRoute && draft !== undefined && draft !== null && 
          <span>Draft: {draft}</span>
        }
      </div>
    </div>
    <div className="flex flex-col justify-end">
      <span className='text-4xl font-bold'>
        {paid !== undefined && paid !== null ? 
          (paid + (unpaid || 0) + (vendorRoute && draft ? draft : 0)) : 
          unpaid
        }
      </span>
    </div>
  </div>}
    </div>
  )
}

export default DashboardCards;
